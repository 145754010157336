import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Row, Col } from 'antd';
import Container from './Container';
import ApplyButton from './ApplyButton';
import ClVideo from './ClVideo';

function VideoTextCard({
  position = 'left',
  video,
  cover,
  image,
  imageCol = 12,
  textCol = 12,
  title = '',
  className = '',
  hideApplyBtn,
  children,
  imgWidth,
  imgHeight,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const [displayImgHeight, setDisplayImgHeight] = useState(imgHeight);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setDisplayImgHeight(((window.innerWidth - 100) * 0.8 * imgHeight) / imgWidth);
    }
  }, []);

  return (
    <section className={`video-text-card ${className}`}>
      <Container fluid="xl">
        <div className="card-wrapper">
          <h3 className="content-title">{title}</h3>
          <Row gutter={{ lg: 30 }}>
            <Col lg={imageCol} md={24} sm={24} xs={24} className="video-col">
              {image ? (
                <div className="image-wrapper">
                  <img src={image} alt="video-holder" width={imgWidth} height={displayImgHeight} />
                </div>
              ) : (
                <ClVideo video={video} cover={cover} videoId="convertlab-video" />
              )}
            </Col>
            <Col lg={textCol} md={24} sm={24} xs={24} className="content">
              <Fade
                left={!isMobile && position !== 'left'}
                right={!isMobile && position === 'left'}
                bottom={isMobile}
                duration={1000}
                delay={200}
                distance="30px"
              >
                <div className="content-inner">
                  {children}
                  {!hideApplyBtn && (
                    <div className="apply-btn-wrapper">
                      <ApplyButton size="large" className="blue" buttonPosition="middle">
                        申请试用
                      </ApplyButton>
                    </div>
                  )}
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

VideoTextCard.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  video: PropTypes.string,
  cover: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  hideApplyBtn: PropTypes.bool,
  imageCol: PropTypes.number,
  textCol: PropTypes.number,
  children: PropTypes.node,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
};

export default VideoTextCard;
