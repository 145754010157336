import React from 'react';
import PropTypes from 'prop-types';

function ImageTextDescription({ label = '', content = '' }) {
  return (
    <div className="description-item">
      {label && <span className={`description-label ${!content ? 'no-content' : ''}`}>{`${label}${content ? ': ' : ''}`}</span>}
      <span className="description-content">{content}</span>
    </div>
  );
}

ImageTextDescription.propTypes = {
  label: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default ImageTextDescription;
