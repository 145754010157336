import React from 'react';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import MAContentList from '../components/MAContentList';
import DigitalTransformation from '../components/DigitalTransformation';
import HomeLogos from '../components/HomeLogos';
import MaBanner from '../images/dm-banner-image.png';
import BlockIconText from '../components/BlockIconText';

const VIDEO_TITLE = `让重复的工作自动化\n让难做的工作智能化`;
export default () => (
  <Layout current="products" subCurrent="dmhub">
    <ProductsBanner
      title="DM Hub 营销自动化平台"
      description="持续高速增长始终是企业的核心营销目标，DM Hub基于AI Hub智能化和MA流程自动化的客户全生命周期管理，让营销部门面对数以亿计的客户/会员群体，真正拥有了精细化运营和增长的核心能力。"
      img={MaBanner}
      imgWidth={501}
      imgHeight={331}
      video="https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/dmhub.mp4"
      videoId="cl-dmhub"
      cover="https://convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-dm.jpg"
    />
    <VideoTextCard
      title={VIDEO_TITLE}
      video="https://cltemplate.oss-cn-hangzhou.aliyuncs.com/video/dmhub-ma.mp4"
    >
      <BlockIconText content="营销自动化运营，减少重复性工作，降低营销成本，提高市场ROI" color="blue" />
      <BlockIconText content="全新自动流3.0引擎，支持营销场景自动化设置，提高决策和执行效率" color="green" letterSpace={-0.5} />
      <BlockIconText content="企业级UDO（自定义对象）能力，支持泛行业的营销对象多变性" color="blue" />
      <BlockIconText content="可视化客户旅程编辑，AI智能化推荐引擎，提升客户体验和满意度" color="green" />
    </VideoTextCard>
    <MAContentList />
    <DigitalTransformation
      title="丰富的可扩展和企业级能力"
      boxList={[
        { id: '1', title: 'AI智能营销', content: 'AI Hub模块赋能营销全流程应用场景' },
        { id: '2', title: '开放性和扩展性', content: '内建Open Hub营销应用市场' },
        { id: '3', title: '全渠道触点连接', content: '覆盖市场部门的主流营销渠道' },
        { id: '4', title: '企业级功能支持', content: '模板、权限、审批、沟通中控' },
        { id: '5', title: '标签体系顾问', content: '完善的行业标签应用解决和实施方案' },
        { id: '6', title: '运营策略咨询', content: '以业务价值和场景为导向的BA顾问服务', letterSpacing: '-1px' },
      ]}
      marginBottom
    />
    <HomeLogos />
  </Layout>
);
