import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'antd';
import ApplyButton from './ApplyButton';
import Container from './Container';
import ClVideo from './ClVideo';

function ProductsBanner({
  title,
  description,
  description1,
  img,
  imgWidth,
  sectionId,
  video,
  videoId,
  cover,
  extraButton,
}) {
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const ref = useRef();

  const toggleViewModalVisible = () => {
    if (videoModalVisible && ref.current) {
      ref.current.handleVideoPause();
    }
    setVideoModalVisible(!videoModalVisible);
  };
  return (
    <section id="banner" className={`ma-banner ${sectionId}`}>
      <div className="bg-figures" />
      <Container>
        <Row gutter={50}>
          <Col lg={14} md={15} sm={24} className="banner-content-wrapper">
            <div>
              <h1>{title}</h1>
              <h4>
                <div>{description}</div>
                <div>{description1}</div>
              </h4>
              <ApplyButton size="large" className="green" buttonPosition="middle">
                申请试用
              </ApplyButton>
              {extraButton}
            </div>
          </Col>
          <Col lg={10} md={9} sm={24} className={`banner-image-wrapper ${video ? 'isvideo' : ''}`}>
            <img
              style={{ width: imgWidth }}
              src={img}
              className="banner-image"
              alt="banner"
              onClick={video ? toggleViewModalVisible : null}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        visible={videoModalVisible}
        onCancel={toggleViewModalVisible}
        footer={null}
        maskStyle={{
          backgroundColor: 'rgba(0,0,0,.8)',
        }}
        width={1024}
        className="product-video-modal"
      >
        <ClVideo video={video} videoId={videoId} cover={cover} ref={ref} autoPlay />
      </Modal>
    </section>
  );
}

ProductsBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  description1: PropTypes.string,
  img: PropTypes.string,
  imgWidth: PropTypes.number,
  sectionId: PropTypes.string,
  video: PropTypes.string,
  videoId: PropTypes.string,
  cover: PropTypes.string,
  extraButton: PropTypes.node,
};

export default ProductsBanner;
