import React from 'react';
import PropTypes from 'prop-types';

function BlockIconText({ color = '', content = '', letterSpace = 0, className = '' }) {
  return (
    <div className="icon-text">
      {color && <span className={`icon ${color}`} />}
      <span className={`text ${!color ? 'no-icon' : ''} ${className}`} style={letterSpace ? { letterSpacing: `${letterSpace}px` } : null}>{content}</span>
    </div>
  );
}

BlockIconText.propTypes = {
  color: PropTypes.oneOf(['green', 'blue']),
  content: PropTypes.string,
  letterSpace: PropTypes.number,
  className: PropTypes.string,
};

export default BlockIconText;
