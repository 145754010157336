import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import LazyLoad from 'react-lazyload';
import { Row, Col, Button, Modal } from 'antd';
import ApplyButton from './ApplyButton';
import ClVideo from './ClVideo';

function ImageText({
  position = 'left',
  imageSize = 11,
  image,
  imgWidth,
  imgHeight,
  title = '',
  title2 = '',
  title3 = '',
  subTitle = '',
  detailButton,
  buttonType,
  children,
  isVideo,
  video,
  videoId,
  cover,
  noFade,
  productId,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [displayImgHeight, setDisplayImgHeight] = useState(imgHeight);
  const ref = useRef();

  useEffect(() => {
    const { innerWidth } = window;
    if (innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setDisplayImgHeight(Math.round(((innerWidth - 30) * 0.8 * imgHeight) / imgWidth));
    }
  }, []);

  const toggleViewModalVisible = () => {
    if (videoModalVisible && ref.current) {
      ref.current.handleVideoPause();
    }
    setVideoModalVisible(!videoModalVisible);
  };

  const imageContent = (
    <LazyLoad height={displayImgHeight}>
      <img
        src={image}
        width={imgWidth}
        height={displayImgHeight}
        className={`img-fluid ${isVideo ? 'show-hover' : ''}`}
        onClick={isVideo ? toggleViewModalVisible : null}
        alt="content"
      />
    </LazyLoad>
  );

  const textContent = (
    <>
      {subTitle ? (
        <>
          <h4 className="content-main-title">{title}</h4>
          <h5 className="content-sub-title">{subTitle}</h5>
        </>
      ) : (
        <>
          <h4 className="content-title">{title}</h4>
          {title2 && <h4 className="content-title content-title-2">{title2}</h4>}
          {title3 && <h4 className="content-title mini">{title3}</h4>}
        </>
      )}
      {children}
      {buttonType === 'join' ? (
        <Button className="join-button" type="primary">
          <a href="mailto:info@convertlab.com">申请加入</a>
        </Button>
      ) : (
        <div className="apply-btn-wrapper">
          <ApplyButton size="large" className="blue" buttonPosition="middle" productId={productId}>
            申请试用
          </ApplyButton>
          {detailButton}
        </div>
      )}
    </>
  );

  return (
    <Row className="image-text">
      <Col
        lg={{ span: imageSize, order: position === 'left' ? 1 : 2 }}
        xs={{ span: 24, order: 1 }}
        className={isVideo ? 'video' : 'image'}
      >
        {noFade ? (
          imageContent
        ) : (
          <Fade
            left={!isMobile && position === 'left'}
            right={!isMobile && position !== 'left'}
            bottom={isMobile}
            duration={600}
            delay={200}
            distance="30px"
          >
            {imageContent}
          </Fade>
        )}
      </Col>
      <Col
        lg={{ span: 24 - imageSize, order: position === 'left' ? 2 : 1 }}
        xs={{ span: 24, order: 2 }}
        className="content"
      >
        {noFade ? (
          textContent
        ) : (
          <Fade
            left={!isMobile && position !== 'left'}
            right={!isMobile && position === 'left'}
            bottom={isMobile}
            duration={1000}
            delay={200}
            distance="30px"
          >
            {textContent}
          </Fade>
        )}
      </Col>
      <Modal
        visible={videoModalVisible}
        onCancel={toggleViewModalVisible}
        footer={null}
        maskStyle={{
          backgroundColor: 'rgba(0,0,0,.8)',
        }}
        width={1024}
        className="product-video-modal"
      >
        <ClVideo video={video} videoId={videoId} cover={cover} ref={ref} autoPlay />
      </Modal>
    </Row>
  );
}

ImageText.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  image: PropTypes.string,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  title: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
  subTitle: PropTypes.string,
  imageSize: PropTypes.oneOf([10, 11, 12]),
  detailButton: PropTypes.node,
  buttonType: PropTypes.string,
  children: PropTypes.node,
  isVideo: PropTypes.bool,
  video: PropTypes.string,
  videoId: PropTypes.string,
  cover: PropTypes.string,
  noFade: PropTypes.bool,
  productId: PropTypes.string,
};

export default ImageText;
