import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Container from './Container';

function VerticalTextBox({ title, content, letterSpacing }) {
  return (
    <Col md={8} sm={12} xs={12} className="vertical-col">
      <div className="vertical-box">
        <p className="title">{title}</p>
        <p className="content" style={letterSpacing ? { letterSpacing } : null}>{content}</p>
      </div>
    </Col>
  );
}

VerticalTextBox.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  letterSpacing: PropTypes.string,
};

function DigitalTransformation({ title, boxList = [], marginBottom, children }) {
  return (
    <section
      id="digital-transformation"
      className={`digital-transformation ${marginBottom ? 'has-bottom' : ''}`}
      style={typeof marginBottom === 'number' ? { marginBottom } : null}
    >
      <Container>
        <h3 className="title">{title}</h3>
        {boxList.length ? (
          <Row gutter={{ lg: 30, md: 30, sm: 15, xs: 15 }}>
            {boxList.map((item) => (
              <VerticalTextBox
                title={item.title}
                content={item.content}
                letterSpacing={item.letterSpacing}
                key={item.id}
              />
            ))}
          </Row>
        ) : (
          children
        )}
      </Container>
    </section>
  );
}

DigitalTransformation.propTypes = {
  title: PropTypes.string,
  boxList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  marginBottom: PropTypes.bool,
  children: PropTypes.node,
};

export default DigitalTransformation;
