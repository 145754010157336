import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Ma1 from '../images/ma-1.png';
import Ma2 from '../images/ma-2.png';
import Ma3 from '../images/ma-3.png';
import Ma4 from '../images/ma-4.png';
import Ma5 from '../images/ma-5.png';
import Ma6 from '../images/ma-6.png';

export default () => {
  return (
    <div className="content-list-wrapper">
      <h3 className="main-title">如何实现营销自动化</h3>
      <div>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ma1} imgWidth={417} imgHeight={335} title="全渠道获客管理">
              <ImageTextDescription
                label="打通营销触点"
                content="网站、微信，App、短信、邮件、广告、小程序"
              />
              <ImageTextDescription
                label="企业应用系统"
                content="直播系统、会员系统、CRM/ERP/OA、客服系统"
              />
              <ImageTextDescription
                label="线上交易渠道"
                content="淘宝、天猫、京东、支付宝生活号、自建商城"
              />
              <ImageTextDescription
                label="线下交易渠道"
                content="门店、POS、一物一码、导购、卡券"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ma2} imgWidth={447} imgHeight={302} title="智能化客户洞察" position="right">
              <ImageTextDescription
                label="建立标签体系"
                content="建立体系化的客户标签和内容标签，提供行业标签解决方案和顾问服务"
              />
              <ImageTextDescription
                label="形成用户画像"
                content="通过标签、属性、来源、事件、活跃度等指标形成精准360°客户画像"
              />
              <ImageTextDescription
                label="精准客户分层"
                content="通过客户画像背后的多级维度，智能化圈群分组"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ma3} imgWidth={435} imgHeight={275} title="客户旅程和自动化流程">
              <ImageTextDescription
                label="客户旅程编辑器"
                content="零代码可视化客户旅程编辑，并自带丰富旅程模板"
              />
              <ImageTextDescription
                label="自动化流程设计"
                content="第三代智能营销引擎，支持亿级超大规模自动化营销"
              />
              <ImageTextDescription
                label="智能化策略"
                content="根据每个行业的不同营销场景和需求总结出的最佳实践"
              />
              <ImageTextDescription
                label="丰富的执行和控制模块"
                content="根据客户行为特征进行实时流程执行和控制"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ma4} imgWidth={396} imgHeight={315} title="个性化交互和规模化触达" position="right">
              <ImageTextDescription label="互动和内容管理" content="所见即所得的内容创建工具" />
              <ImageTextDescription
                label="精准MOT管理"
                content="帮助品牌抓住营销“关键时刻”（Moment of Truth，简称MOT）"
              />
              <ImageTextDescription
                label="Always-on全时沟通"
                content="大规模的个性化不间断（always-on）用户运营"
              />
              <ImageTextDescription
                label="客户培育和孵化"
                content="通过营销活动和客户互动管理（CEM）实现客户的持续运营孵化"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ma5} imgWidth={415} imgHeight={334} title="数据分析与监测">
              <ImageTextDescription
                label="客户分析"
                content="客户特征分析、行为分析、时间分布分析"
              />
              <ImageTextDescription label="订单分析" content="订/退单分析、RFM分析、复购分析" />
              <ImageTextDescription label="转化分析" content="路径分析、转化间隔分析、漏斗分析" />
              <ImageTextDescription
                label="数据看板"
                content="根据分析需求自定义分析维度，形成可视化数据看板"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={Ma6} imgWidth={442} imgHeight={287} title="持续敏捷营销和智能策略迭代" position="right">
              <ImageTextDescription content="通过快速高效的A/B测试，确定营销策略核心" />
              <ImageTextDescription content="建立基于精准客户洞察的营销框架和策略" />
              <ImageTextDescription content="部署经过验证的策略，自动执行并持续优化" />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
